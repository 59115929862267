// User Variables
$primary:  #a8382b;
$theme-colors: ();
$theme-colors: map-merge(
(
"primary": $primary,
"secondary": $secondary,
"success": $success,
"info":  $info,
"warning": $warning,
"danger":  $danger,
"light":  $light,
"dark":  $dark
),
$theme-colors
);

// scss-docs-start theme-colors-rgb
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

//Theme gradient Colors - Starting and ending point
$grad-start: $primary;
// $grad-end:  #6b6b6b;
$grad-end: darken($primary, 30%);

$link-color: $primary;
$link-hover-color:   darken($link-color, 10%);
$navbar-light-hover-color:   $primary;
$navbar-light-active-color:   $primary;
$dropdown-link-hover-color:   $primary !important;
$dropdown-link-active-color:  $primary;